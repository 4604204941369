
@font-face {
  font-family: 'Tangerine-Bold';
  src: url('../src/assets/font/Tangerine-Bold.ttf'); 
}
@font-face {
  font-family: 'Tangerine-Regular';
  src: url('../src/assets/font/Tangerine-Regular.ttf'); 
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,body,#root{
  height: 100%;
}